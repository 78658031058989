import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import { TextField } from "mui-rff";
import { Alert, Stack, Box, Button, Typography } from "@mui/material";
import { useRequestCode } from "../../auth/useRequestSms";
import { useLogin } from "../../auth/useLogin";
import { ResendSms } from "./ResendSms";
import { WidgetLayout } from "../../components/WidgetLayout";
import { SubmitBtn } from "../../components/SubmitBtn";
import useLocalStorage from "../../modules/ui/hooks/useLocalStorage";
import {
  AML_STORAGE,
  STEPPER_COMPLETED,
} from "../../modules/onBoarding/models";
import "react-phone-number-input/style.css";
import { parseSignInError } from "../../utils/parseSignInError";
import { PhoneInputWithCountry } from "../../modules/ui/components/PhoneInputWithCountry";
import {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import { TCountryPhoneCode } from "../../models/CountryNumbers";
import { TextButton } from "../../modules/ui/components/TextButton";
import { AuthContext, AuthContextValue } from "../../auth/AuthProvider";

type FormValues = {
  phoneNumber?: string;
  email?: string;
  code?: string;
};

// const excludedNumberCodes = ["+90"];
export const SigninScreen = () => {
  const { externalEmail } = useContext<AuthContextValue>(AuthContext);
  const [step, setStep] = useState<"data" | "code">("data");
  const [type, setType] = useState<"phone" | "e-mail">("phone");
  const [countryCode, setCountryCode] = useLocalStorage<TCountryPhoneCode>(
    "countryCode",
    {
      callingCode: `+${getCountryCallingCode("LU")}`,
      countryName: "Luxembourg",
    }
  );
  const [phoneNumber, setPhoneNumber] = useLocalStorage<string>("phone", "");
  const [emailToSignIn, setEmailToSignIn] = useState(externalEmail);
  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  const [, setUserEmail] = useLocalStorage("email", null);
  const [smsResult, smsLoading, smsError, smsRequest] = useRequestCode(type);
  const [, loginLoading, loginError, loginRequest] = useLogin(type);

  const handleChangeStep = useCallback(() => {
    setStep("data");
  }, []);

  useEffect(() => {
    if (smsResult) {
      setStep("code");
    }
    return () => setStep("data");
  }, [smsResult]);

  useEffect(() => {
    if (phoneNumber.includes("+")) {
      setPhoneNumber(parsePhoneNumber(phoneNumber)?.nationalNumber as string);
    }
  }, [phoneNumber, setPhoneNumber]);

  const onSubmit = useCallback(
    (formData: FormValues) => {
      if (step === "data") {
        localStorage.setItem(AML_STORAGE, "false");
        localStorage.setItem(STEPPER_COMPLETED, "{}");
        if (type === "phone" && formData.phoneNumber) {
          const phoneNumber = formData.phoneNumber.split("+").join("");
          let fullPhoneNumber = countryCode.callingCode + phoneNumber;
          smsRequest(fullPhoneNumber);
          setPhoneNumber(phoneNumber);
          setFullPhoneNumber(fullPhoneNumber);
        } else if (formData.email) {
          smsRequest(formData.email);
          setEmailToSignIn(formData.email);
        }
      } else if (step === "code") {
        if (formData.code && smsResult?.salt) {
          setUserEmail(null);
          if (fullPhoneNumber) {
            loginRequest(fullPhoneNumber, formData.code.trim(), smsResult.salt);
          } else if (emailToSignIn) {
            loginRequest(emailToSignIn, formData.code.trim(), smsResult.salt);
          }
        }
      }
    },
    [
      smsResult,
      smsRequest,
      loginRequest,
      setPhoneNumber,
      fullPhoneNumber,
      emailToSignIn,
      step,
      setUserEmail,
      countryCode,
      type,
    ]
  );

  const validate = useCallback(
    (formData: FormValues) => {
      const errors: ValidationErrors = {};

      if (step === "data" && type === "phone" && !formData.phoneNumber) {
        errors.phoneNumber = true;
      }

      if (step === "data" && type === "e-mail" && !formData.email) {
        errors.email = true;
      }

      if (step === "code" && !formData.code) {
        errors.code = true;
      }

      return errors;
    },
    [step, type]
  );

  const resendSms = useCallback(() => {
    if (fullPhoneNumber) {
      return smsRequest(fullPhoneNumber);
    }
  }, [smsRequest, fullPhoneNumber]);

  const initialValues = useMemo(() => {
    const initialValues: { phoneNumber?: string; email?: string } = {};
    if (phoneNumber) {
      initialValues.phoneNumber = phoneNumber;
    }
    if (externalEmail) {
      initialValues.email = externalEmail;
    }
    return initialValues;
  }, [phoneNumber, externalEmail]);

  const error = smsError || loginError;

  const handleSwitchToEmail = () => {
    setType("e-mail");
    setStep("data");
  };

  return (
    <WidgetLayout
      title={
        step === "code"
          ? "Enter verification code"
          : `Please enter your ${type === "phone" ? "phone number" : "e-mail"}`
      }
      subtitle={
        step === "code"
          ? `Sent to ${fullPhoneNumber || emailToSignIn}.`
          : `We will send a verification code to this ${type}`
      }
      action={
        step === "code" && (
          <Button
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#2968EF",
              textTransform: "capitalize",
            }}
            onClick={handleChangeStep}
          >
            Change
          </Button>
        )
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Stack
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Stack
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
              >
                {step === "data" && type === "phone" && (
                  <PhoneInputWithCountry
                    phoneNumber={phoneNumber}
                    setNumber={setPhoneNumber}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                )}
                {step === "data" && type === "e-mail" && (
                  <TextField
                    placeholder='address@email.com'
                    label='E-mail'
                    name='email'
                    required={true}
                    type='email'
                  />
                )}
                {step === "code" && (
                  <TextField
                    name='code'
                    required={true}
                    autoFocus
                    sx={{ width: "150px" }}
                    inputProps={{
                      style: {
                        paddingBottom: "14px",
                        textAlign: "center",
                        fontWeight: "800",
                        fontSize: "20px",
                        lineHeight: "28px",
                        letterSpacing: "0.5px",
                        color: "#1C1F21",
                      },
                    }}
                    type='tel'
                    variant='standard'
                  />
                )}
                {type === "e-mail" && step !== "code" ? (
                  <Typography sx={{ marginTop: 2 }} variant='body2'>
                    You can switch back to{" "}
                    <TextButton onClick={() => setType("phone")}>
                      phone
                    </TextButton>{" "}
                    verification
                  </Typography>
                ) : (
                  step !== "code" && (
                    // (excludedNumberCodes.includes(countryCode.callingCode) ? (
                    //   <Alert
                    //     sx={{ width: 360, marginTop: 2 }}
                    //     severity={"warning"}
                    //   >
                    //     Unfortunately, Receiving SMS is not supported in{" "}
                    //     {countryCode.countryName}, you can use{" "}
                    //     <TextButton onClick={() => setType("e-mail")}>
                    //       e-mail
                    //     </TextButton>{" "}
                    //     to register and log in
                    //   </Alert>
                    // ) :
                    <Typography sx={{ marginTop: 2 }} variant='body2'>
                      Log in with{" "}
                      <TextButton onClick={() => setType("e-mail")}>
                        e-mail
                      </TextButton>
                      , for registered users only
                    </Typography>
                  )
                )}
                <Box mt={4} />
                {step === "code" && (
                  <ResendSms
                    type={type}
                    handleSwitchToEmail={handleSwitchToEmail}
                    onResend={resendSms}
                  />
                )}
              </Stack>
              <SubmitBtn disabled={smsLoading || loginLoading}>
                Continue
              </SubmitBtn>
              <Box mt={2} />
              {error !== null && (
                <Alert severity='error'>
                  {parseSignInError(
                    error.toString(),
                    "The code has expired. Please resend it and enter the new one."
                  )}
                </Alert>
              )}
            </Stack>
          </form>
        )}
      />
    </WidgetLayout>
  );
};
