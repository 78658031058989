import { FC } from "react";
import { mainTheme } from "../../../../theme/mainTheme";

interface ITextButton {
  children: string;
  onClick: () => void;
}

export const TextButton: FC<ITextButton> = ({ children, onClick }) => {
  return (
    <span
      style={{
        color: mainTheme.palette.primary.main,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
